export default {
  home: "/",

  register: {
    register: "/register",
    completeRegistration: "/register/completeRegistration",
    activationSuccess: "/register/activationSuccess",
    mobileActivationSuccess: "/register/mobileActivationSuccess",
  },

  hotels: {
    hotels: "/hotels",
    hotel: "/hotels/:id",
    book: "/hotels/book",
    paymentDetails: "/hotels/paymentDetails",
    paymentResponse: "/payment/response",
  },

  booking: {
    guestBooking: "/guest_booking/bookingInfo",
  },

  login: {
    login: "/login",
    forgotPassword: "/login/forgotPassword",
    resetPassword: "/reset-password",
    resetPasswordSuccess: "/login/passwordResetSuccess",
    resetPasswordSuccessResponse: "/reset-password/success",
  },

  user: {
    accountSettings: "/user/accountSettings",
    favourites: "/user/favourites",
    reservations: "/user/reservations",
    reservation: "/user/reservations/:id",
    lvlUp: "/user/lvl-up",
    wallet: "/user/wallet",
  },
  static: {
    privacyPolicy: "/legal/privacy",
    termsAndConditions: "/legal/terms",
    aboutUs: "/about_us",
  },
};
