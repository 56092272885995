import { useTranslation } from "next-i18next";
import { useState, useEffect } from "react";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useUserStore } from "store/useUserStore";
import {
  getNotifications,
  markAllAsRead,
} from "utils/api/notifications/notificationsLib";
import NotificationItem from "./notificationItem";

const calculateTotalUnreadNotifications = (notifications: any) => {
  let total = 0;
  for (let key in notifications)
    for (let subKey in notifications[key])
      notifications[key][subKey].forEach((n: any) => {
        if (!n.read_at) total++;
      });
  return total;
};

function Notifications() {
  const { t: translate } = useTranslation();
  const { deviceToken, token }: any = useUserStore();
  const [activeTab, setactiveTab] = useState("booking");
  const [notifications, setNotifications] = useState<any>({});
  const [activeNotifications, setActiveNotifications] = useState<any>({});
  const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(0);

  useEffect(() => {
    deviceToken &&
      token &&
      getNotifications(deviceToken, token).then((res) => {
        let total = calculateTotalUnreadNotifications(res.data.data);
        setNotifications(res.data.data);
        setActiveNotifications(res.data.data.booking);
        setTotalUnreadNotifications(total);
      });
  }, [deviceToken]);

  useEffect(() => {
    if (activeTab === "all") setActiveNotifications(notifications.all ?? {});
    else setActiveNotifications(notifications.booking ?? {});
  }, [activeTab]);

  const handleReadAllNotifications = () => {
    if (totalUnreadNotifications === 0) return;

    markAllAsRead(deviceToken, token)
      .then((res) => {
        setNotifications((notifications: any) => {
          Object.keys(notifications).forEach((key) => {
            Object.keys(notifications[key]).forEach((k) => {
              notifications[key][k].forEach((n: any) => {
                n.read_at = true;
              });
            });
          });
          setActiveNotifications(notifications[activeTab]);
          setTotalUnreadNotifications(0);

          return { ...notifications };
        });
      })
      .catch((err) => {});
  };

  return (
    <div className="dropdown dropdown-end">
      <label tabIndex={0} className="relative flex cursor-pointer avatar">
        <IoIosNotificationsOutline
          className="mt-3 cursor-pointer text-slate-700 "
          size={24}
        />
        {totalUnreadNotifications > 0 && (
          <span className="absolute w-4 h-4 text-xs text-center text-white rounded-full -right-1 bg-opacity-80 top-1 bg-error">
            {totalUnreadNotifications}
          </span>
        )}
      </label>
      <div
        tabIndex={0}
        className="p-4 mt-5 shadow h-80 w-72 dropdown-content card card-compact bg-base-100 "
      >
        <div className="flex justify-between">
          <h3 className="text-lg">
            {translate("notifications.Notifications")}
          </h3>
          <h3
            onClick={handleReadAllNotifications}
            className="mt-2 text-xs cursor-pointer hover:underline text-primary"
          >
            {translate("notifications.Mark all as read")}
          </h3>
        </div>
        <div className="justify-center my-3 tabs item-center">
          <a
            onClick={() => setactiveTab("all")}
            className={`text-xs rounded-none tab tab-bordered
          ${activeTab === "all" && `tab-active`}`}
          >
            All
          </a>
          <a
            onClick={() => setactiveTab("booking")}
            className={`text-xs rounded-none tab tab-bordered
          ${activeTab === "booking" && `tab-active`}`}
          >
            Bookings
          </a>
        </div>
        {Object.keys(activeNotifications).length > 0 && (
          <div className="overflow-y-auto">
            {Object.keys(activeNotifications).map((key) => {
              return (
                <div key={key}>
                  <h3 className="mt-3 mb-2 text-sm capitalize opacity-50">
                    {key.replace("Notifications", "")}
                  </h3>
                  {Object.keys(activeNotifications[key]).length === 0 && (
                    <h3 className="text-sm text-center opacity-30">
                      {translate("notifications.No notifications")}
                    </h3>
                  )}
                  {activeNotifications[key].map((notification: any) => {
                    return (
                      <NotificationItem
                        key={notification.id}
                        activeTab={activeTab}
                        notificationDay={key}
                        notification={notification}
                        setNotifications={setNotifications}
                        setActiveNotifications={setActiveNotifications}
                        setTotalUnreadNotifications={
                          setTotalUnreadNotifications
                        }
                        calculateTotalUnreadNotifications={
                          calculateTotalUnreadNotifications
                        }
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Notifications;
