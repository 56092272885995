import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosNotificationsOutline } from "react-icons/io";
import loginDoor from "public/assets/login-door.svg";
import Image from "next/image";
import Notifications from "components/notifications/notifications";
import { useUserStore } from "store/useUserStore";
import { slice } from "lodash";

function ResponsiveNavHeader({ nav, setNav }: any) {
  const { push, asPath } = useRouter();
  const { user }: any = useUserStore();
  const [userData, setuserData] = useState<any>(null);

  const handleRedirect = () => {
    sessionStorage.setItem("redirectPath", asPath);
    push("/login");
  };

  useEffect(() => {
    if (user) {
      setuserData(user);
    }
  }, []);
  return (
    <div className={`flex flex-col m-3 ${nav ? "block" : "hidden"}`}>
      <div className="flex items-center justify-between">
        <AiOutlineClose
          className="opacity-50"
          size={25}
          cursor={"pointer"}
          onClick={() => setNav(false)}
        />
        <Notifications />
      </div>
      <div className="flex flex-col items-center justify-center gap-2 mx-auto mt-3">
        {userData ? (
          <>
            <div className="flex items-center justify-center w-20 h-20 rounded-full bg-primary bg-opacity-40">
              <h3 className="text-3xl uppercase text-primary">
                {userData?.first_name.slice(0, 1)}{" "}
                {userData?.last_name?.slice(0, 1)}
              </h3>
            </div>
            <h3 className="text-sm">
              Hello,{"  "} {userData?.first_name} {userData?.last_name}
            </h3>
            <h3 className="text-sm opacity-50">{userData?.email}</h3>
          </>
        ) : (
          <>
            <Image alt="login" src={loginDoor} width={100} height={100} />
            <h3>Why having an account?</h3>
            <p className="text-xs text-center opacity-50">
              Get access to exclusive deals, earn and redeem wallet
              <br />
              and travel credits points, save travelers details and quick
              <br />
              booking and manage your upcoming booking
            </p>
            <button
              className="bg-[#0081df] text-white py-3 px-8 mt-2"
              onClick={() => handleRedirect()}
            >
              Login
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default ResponsiveNavHeader;
