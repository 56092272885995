import { IoIosNotificationsOutline } from "react-icons/io";
import { RxAvatar } from "react-icons/rx";

import { RiArrowDropDownLine } from "react-icons/ri";
import { useUserStore } from "store/useUserStore";
import { useRouter } from "next/router";

import calendar from "public/assets/icons/calendar.svg";
import wallet from "public/assets/icons/wallet.svg";
import credit from "public/assets/icons/credit.svg";
import lvlUp from "public/assets/icons/redeem-points.svg";
import favourties from "public/assets/icons/favourite.svg";
import account from "public/assets/icons/google.svg";
import Image from "next/image";
import Link from "next/link";
import { BiRightArrowAlt } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import Notifications from "components/notifications/notifications";
import paths from "utils/configs/paths";
function UserHeaderNav({ user, nav, locale }: any) {
  const { logout }: any = useUserStore();
  const { t: translate } = useTranslation();
  const { push, asPath } = useRouter();

  const handleLogout = () => {
    push({ pathname: "/login" });
    logout();
  };

  return (
    <div className="flex gap-2 text-slate-600">
      <div className="hidden md:block">
        <Notifications />
      </div>
      <div className="hidden mt-1 dropdown dropdown-end md:flex">
        <label tabIndex={0} className="m-1 cursor-pointer avatar">
          {user.image ? (
            <div className="w-10 h-10">
              <img
                alt="user"
                width="24"
                height="24"
                className="object-cover w-1/2"
                src={user.image}
              />
            </div>
          ) : (
            <RxAvatar className="opacity-50 text-secondary" size={32} />
          )}
          <RiArrowDropDownLine size={32} />
        </label>
        <ul
          tabIndex={0}
          className="w-56 p-2 text-xs shadow mt-14 user-dropdown dropdown-content menu bg-base-100 rounded-box"
        >
          <li className="menu-title">
            <span className="font-normal uppercase">
              {translate("general.General")}
            </span>
          </li>
          <li className="border-b rounded-none">
            <Link href="/user/accountSettings">
              {" "}
              <Image
                src={account}
                alt="account"
                width={24}
                className="rounded-none"
              />
              {translate("user.Account settings")}
            </Link>
          </li>
          <li className="border-b rounded-none">
            <Link href="/user/reservations">
              <Image
                src={calendar}
                alt="account"
                width={24}
                className="rounded-none"
              />{" "}
              {translate("user.My reservations")}
            </Link>
          </li>
          <li className="rounded-none">
            <Link href="/user/favourites">
              <Image
                src={favourties}
                alt="account"
                width={24}
                className="rounded-none"
              />
              {translate("user.Favourites")}
            </Link>
          </li>
          {/* <li className="rounded-none">
            <Link href={paths.user.lvlUp}>
              <Image
                src={lvlUp}
                alt="lvl up"
                width={24}
                className="rounded-none"
              />
              {translate("user.Lvl Up")}
            </Link>
          </li> */}
          {/* <li className="mt-3 menu-title">
            <span className="font-normal uppercase">
              {translate("general.Transactions")}
            </span>
          </li>
          <li>
            <Link href="/user/wallet">
              {" "}
              <Image
                src={wallet}
                alt="account"
                width={24}
                className="rounded-none"
              />{" "}
              {translate("user.Wallet")}
            </Link>
          </li>
          <li>
            <a>
              {" "}
              <Image
                src={credit}
                alt="account"
                width={24}
                className="rounded-none"
              />
              {translate("user.Travel credits")}
            </a>
          </li> */}
          <li>
            <a
              className="mt-3 font-normal text-white normal-case rounded-3xl btn btn-error"
              onClick={handleLogout}
            >
              {translate("user.Logout")}
            </a>
          </li>
        </ul>
      </div>
      <div
        className={
          nav ? "w-full md:hidden text-[15px] flex-col gap-5 mx-5 " : "hidden"
        }
      >
        <div className="text-sm text-gray-200 uppercase">
          {translate("general.General")}
        </div>
        <Link
          href="/user/accountSettings"
          className="flex items-center justify-between w-full p-4 border-b rounded-none"
        >
          <div className="flex items-center gap-2">
            <Image
              src={account}
              alt="account"
              width={40}
              className="rounded-none"
            />
            <h3>{translate("user.Account settings")}</h3>
          </div>
          {locale === "en" ? (
            <IoIosArrowForward className="text-gray-400" size={20} />
          ) : (
            <IoIosArrowBack className="text-gray-400" size={20} />
          )}
        </Link>

        <Link
          href="/user/reservations"
          className="flex items-center justify-between w-full p-4 border-b rounded-none"
        >
          <div className="flex items-center gap-2">
            <Image
              src={calendar}
              alt="account"
              width={40}
              className="rounded-none"
            />
            <h3>{translate("user.My reservations")}</h3>
          </div>
          {locale === "en" ? (
            <IoIosArrowForward className="text-gray-400" size={20} />
          ) : (
            <IoIosArrowBack className="text-gray-400" size={20} />
          )}
        </Link>

        <Link
          href="/user/favourites"
          className="flex items-center justify-between w-full p-4 border-b rounded-none screen"
        >
          <div className="flex items-center gap-2 ">
            <Image
              src={favourties}
              alt="account"
              width={40}
              className="rounded-none"
            />
            <h3>{translate("user.Favourites")}</h3>
          </div>
          {locale === "en" ? (
            <IoIosArrowForward className="text-gray-400" size={20} />
          ) : (
            <IoIosArrowBack className="text-gray-400" size={20} />
          )}
        </Link>
        {/* 
        <Link
          href="/"
          className="flex items-center justify-between w-full p-4 border-b rounded-none"
        >
          <div className="flex items-center gap-2">
            <Image
              src={wallet}
              alt="account"
              width={40}
              className="rounded-none"
            />
            {translate("user.Wallet")}
          </div>
          {locale === "en" ? (
            <IoIosArrowForward className="text-gray-400" size={20} />
          ) : (
            <IoIosArrowBack className="text-gray-400" size={20} />
          )}
        </Link> */}

        {/* <Link
          href="/"
          className="flex items-center justify-between w-full p-4 border-b"
        >
          <div className="flex items-center gap-2">
            <Image
              src={credit}
              alt="account"
              width={40}
              className="rounded-none"
            />
            Travel credit
          </div>
          {locale === "en" ? (
            <IoIosArrowForward className="text-gray-400" size={20} />
          ) : (
            <IoIosArrowBack className="text-gray-400" size={20} />
          )}
        </Link> */}

        <a
          className="w-full mt-3 text-lg font-normal text-white normal-case rounded-3xl btn btn-error"
          onClick={handleLogout}
          style={{ fontFamily: "RobotoSemiBold" }}
        >
          {translate("user.Logout")}
        </a>
      </div>
    </div>
  );
}

export default UserHeaderNav;
