import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineMenu } from "react-icons/ai";

import credit from "public/assets/icons/money-exchange.svg";
import langugue from "public/assets/icons/translate.svg";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import saudi from "public/assets/saudi.svg";
import english from "public/assets/english.svg";
import { useUserStore } from "store/useUserStore";

function MobileNavigationMenu({
  nav,
  setNav,
  handleNav,
  lang,
  setLang,
  currentCurrency,
  setCurrentCurrency,
  setCurrency,
  mobileUSDNav,
  setMobileUSDNav,
  userLogged,
}: any) {
  const { locales, push, asPath, pathname, locale } = useRouter();
  const { t: translate } = useTranslation();
  const { token, user }: any = useUserStore();

  return (
    <>
      <div className="hidden gap-2 mt-3 mr-1 md:flex">
        {pathname === "/login" ? (
          <Link className="text-primary" href={"/"}>
            <h3 className="text-sm">{translate("general.Home") as string}</h3>
          </Link>
        ) : userLogged && token ? (
          ""
        ) : (
          <Link className="text-primary" href={"/login"}>
            <h3 className="text-sm"> {translate("home.Login")}</h3>
          </Link>
        )}
      </div>
      <div className="flex flex-col gap-2 md:hidden mx-3 w-full text-[15px] ">
        <div
          className="flex flex-row items-center justify-between p-4 border-b rounded-none cursor-pointer"
          onClick={() => setLang(!lang)}
        >
          <div className="flex items-center gap-2">
            <Image
              src={langugue}
              alt="account"
              width={40}
              className="rounded-none"
            />

            <h3>Languages</h3>
          </div>
          <div className="flex items-center gap-3 ">
            {locale === "en" ? "EN" : "العربية"}
            {locale === "en" ? (
              <IoIosArrowForward className="text-gray-400" size={20} />
            ) : (
              <IoIosArrowBack className="text-gray-400" size={20} />
            )}
          </div>
        </div>
        <div
          className={
            lang
              ? "absolute z-20 bg-gray-50  w-full h-full top-0 right-0 flex flex-col"
              : "hidden"
          }
        >
          <div className="flex justify-between p-3 bg-white">
            {locale === "en" ? (
              <AiOutlineArrowLeft
                size={30}
                onClick={() => setLang(false)}
                className={`text-gray-400 cursor-pointer ${
                  nav ? "" : "hidden"
                }`}
              />
            ) : (
              <AiOutlineArrowRight
                size={30}
                onClick={() => setLang(false)}
                className={`text-gray-400 cursor-pointer ${
                  nav ? "" : "hidden"
                }`}
              />
            )}
            <h3>Change Languages</h3>
            <AiOutlineMenu
              style={{ color: "grey" }}
              size={35}
              cursor={"pointer"}
              className=""
              onClick={() => handleNav()}
            />
          </div>

          {locales?.map((lang) => (
            <div
              className="px-3 mt-2 text-sm uppercase hover:cursor-pointer"
              onClick={() => {
                //  setLanguage(locale);
                push(pathname, asPath, { locale: lang });
              }}
              key={lang}
            >
              <div
                className={`flex gap-4 items-center border p-6 bg-white ${
                  locale === lang ? "border-[#0081df]" : ""
                }`}
              >
                <Image
                  src={lang === "en" ? english : saudi}
                  alt="sar"
                  width={40}
                  className="rounded-none"
                />
                <div className="flex justify-between w-full normal-case">
                  {lang === "en" ? "English" : "العربية"}
                  <AiOutlineCheck
                    className={locale === lang ? "" : "hidden"}
                    style={{ color: "blue" }}
                    size={25}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          className="flex flex-row items-center justify-between p-4 border-b rounded-none cursor-pointer"
          onClick={() => setMobileUSDNav(!mobileUSDNav)}
        >
          <div className="flex items-center gap-2">
            <Image
              src={credit}
              alt="account"
              width={40}
              className="rounded-none"
            />
            <h3>Currency</h3>
          </div>
          <div className="flex items-center gap-3">
            {currentCurrency === "USD" ? "USD" : "SAR"}
            {locale === "en" ? (
              <IoIosArrowForward className="text-gray-400" size={20} />
            ) : (
              <IoIosArrowBack className="text-gray-400" size={20} />
            )}
          </div>
        </div>

        <div
          className={
            mobileUSDNav
              ? "absolute z-20 bg-gray-50  w-full h-full top-0 right-0 flex flex-col"
              : "hidden"
          }
        >
          <div className="flex justify-between p-3 bg-white">
            {locale === "en" ? (
              <AiOutlineArrowLeft
                size={30}
                onClick={() => setMobileUSDNav(false)}
                className={`text-gray-400 cursor-pointer ${
                  nav ? "" : "hidden"
                }`}
              />
            ) : (
              <AiOutlineArrowRight
                size={30}
                onClick={() => setMobileUSDNav(false)}
                className={`text-gray-400 cursor-pointer ${
                  nav ? "" : "hidden"
                }`}
              />
            )}
            <h3>Change Currency</h3>
            <AiOutlineMenu
              style={{ color: "grey" }}
              size={35}
              cursor={"pointer"}
              className=""
              onClick={() => handleNav()}
            />
          </div>
          <div className="flex flex-col w-full gap-2 px-3 mt-2 text-sm text-primary">
            <div
              onClick={() => {
                setCurrentCurrency("SAR");
                setCurrency("SAR");
              }}
              className={`flex gap-2 justify-between items-center px-3 py-6 cursor-pointer bg-white ${
                currentCurrency === "SAR" ? "border border-[#0081df]" : ""
              }`}
            >
              <div className="flex items-center gap-2">
                <Image
                  src={saudi}
                  alt="sar"
                  width={40}
                  className="rounded-none"
                />
                <h3>Saudi Riyal - SAR</h3>
              </div>

              {currentCurrency === "SAR" && (
                <AiOutlineCheck style={{ color: "blue" }} size={25} />
              )}
            </div>

            <div>
              <div
                onClick={() => {
                  setCurrentCurrency("USD");
                  setCurrency("USD");
                }}
                className={`flex gap-2 justify-between items-center px-3 py-6 cursor-pointer bg-white ${
                  currentCurrency === "USD"
                    ? "border border-[#0081df] shadow-[#0081df]"
                    : ""
                }`}
              >
                <div className="flex items-center gap-2">
                  <Image
                    src={english}
                    alt="usd"
                    width={40}
                    className="rounded-none"
                  />
                  <h3>US Dollar - USD</h3>
                </div>
                {currentCurrency === "USD" && (
                  <AiOutlineCheck style={{ color: "blue" }} size={25} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileNavigationMenu;
