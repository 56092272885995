import { apiServices } from "utils/configs/apiServices";
import { axiosInstance } from "utils/configs/axiosInstance";

export const getNotifications = async (
  deviceToken: string,
  userToken: string | null
) => {
  try {
    const response = await axiosInstance.get(
      `${apiServices.notifications.getNotifications}?device_token=${deviceToken}`
    );
    return { data: response.data };
  } catch (error: any) {
    return { error: error.response.data };
  }
};

export const deleteNotification = async (
  notificationId: string,
  userToken: string | null,
  deviceToken: string
) => {
  try {
    const response = await axiosInstance.delete(
      `${apiServices.notifications.getNotifications}/${notificationId}?device_token=${deviceToken}`
    );
    return { data: response.data };
  } catch (error: any) {
    return { error: error.response.data };
  }
};

export const readNotification = async (
  notificationId: string,
  userToken: string | null
) => {
  try {
    const response = await axiosInstance.get(
      `${apiServices.notifications.getNotifications}/${notificationId}`
    );
    return { data: response.data };
  } catch (error: any) {
    return { error: error.response.data };
  }
};

export const markAllAsRead = async (
  deviceToken: string,
  userToken: string | null
) => {
  try {
    const response = await axiosInstance.get(
      `${apiServices.notifications.readNotifications}?device_token=${deviceToken}`
    );
    return { data: response.data };
  } catch (error: any) {
    return { error: error.response.data };
  }
};
