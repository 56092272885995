import { update } from "lodash";
import moment from "moment";
import Link from "next/link";
import { MdDeleteForever } from "react-icons/md";
import { useUserStore } from "store/useUserStore";
import {
  deleteNotification,
  readNotification,
} from "utils/api/notifications/notificationsLib";
import paths from "utils/configs/paths";
import { safelyArabicToEnglish } from "utils/helpers/date-helper";

function NotificationItem({
  notification,
  calculateTotalUnreadNotifications,
  setTotalUnreadNotifications,
  setNotifications,
  setActiveNotifications,
  activeTab,
}: any) {
  const { read_at, title, body, type, image, createdAt } = notification;
  const { token, deviceToken }: any = useUserStore();
  const fromAgo = moment(safelyArabicToEnglish(createdAt)).fromNow();
  const notificationUrl =
    activeTab === "booking"
      ? `${paths.user.reservations}/${notification.booking_id}`
      : "#";

  const handleDeleteNotification = () => {
    deleteNotification(notification.id, token, deviceToken)
      .then((res) => {
        setNotifications((notifications: any) => {
          for (let key in notifications)
            for (let subKey in notifications[key])
              notifications[key][subKey] = notifications[key][subKey].filter(
                (n: any) => n.id !== notification.id
              );
          return { ...notifications };
        });
      })
      .catch((err) => {});
  };

  const handleReadNotification = () => {
    if (notification.read_at) return;

    readNotification(notification.id, token)
      .then(() => {
        setNotifications((notifications: any) => {
          for (let key in notifications)
            for (let subKey in notifications[key])
              notifications[key][subKey] = notifications[key][subKey].map(
                (n: any) => {
                  if (n.id === notification.id) return { ...n, read_at: true };
                  return n;
                }
              );
          const total = calculateTotalUnreadNotifications(notifications);
          setTotalUnreadNotifications(total);

          return { ...notifications };
        });
      })
      .catch((err) => {});
  };
  return (
    <div
      onClick={handleReadNotification}
      className={`flex gap-2 p-2 text-xs my-2 rounded-md cursor-pointer hover:bg-base-100 ease-in-out duration-500
    ${read_at ? "bg-base-100" : "bg-neutral"}
    `}
    >
      <div className="w-6">
        <img
          src={image}
          alt="notification"
          width={24}
          className="w-full rounded-full object-fit"
        />
      </div>
      <Link href={notificationUrl}>
        <h3>{title}</h3>
        <p className="mt-2 opacity-50 ">{body}</p>

        <h3 className="mt-2 text-xs">{fromAgo}</h3>
      </Link>
      {activeTab === "booking" && (
        <MdDeleteForever
          onClick={handleDeleteNotification}
          size={25}
          className="opacity-50 cursor-pointer text-error hover:animate-bounce"
        />
      )}
    </div>
  );
}

export default NotificationItem;
